import { Global } from '@emotion/core'
import { Theme, ThemeProvider as CustomThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import {
  AtomsProvider,
  DebugMenu,
  LocalStorageUtil,
  MOCK_TIME,
  ThemeProvider,
  TimeUtil,
  TranslationProvider,
  useFeatureFlags,
  withDebugMenuControls,
} from 'cuenect-web-core'
import dayjs from 'dayjs'
import locale_de from 'dayjs/locale/de'
import RelativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { rgba } from 'emotion-rgba'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { I18nUtil, withTrans } from '../../../i18n'
import { useAuthentication } from '../../../utility/authentication'
import { LoadingIndicator } from '../../atoms/loadingIndicator'
import { Footer } from '../footer'
import { Header } from '../header'
import {
  AgendaCta,
  Button,
  CookieConsent,
  DraggableSlider,
  FakeFullscreen,
  LoadingSpinner,
  MobileVodVideo,
  Modal,
  SlideIn,
} from './../../'
import {
  AppointmentService,
  ProgramEvent,
  ProgramService,
} from './../../../api'
import { isPostEvent, languages } from './../../../config'
import './../../../themes/siemens/global.css'
import { siemensTheme } from './../../../themes/siemens/siemensTheme'
import {
  AnalyticsTransformer,
  LoadAnalytics,
  mq,
  useFullscreenContext,
  useGlobalContext,
  useModalContext,
  useReservationContext,
  useVodContext,
} from './../../../utility'

dayjs.extend(utc)
dayjs.extend(RelativeTime)

export interface InnerContainerParams {
  isTop?: boolean
}

export interface PageParams extends InnerContainerParams {
  pageContext: {
    lang: string
    item?: string
    program?: string
    hidePostEvent?: boolean
  }
  isTop?: boolean
  onScrollerInit: Function
}

export interface PageFrameProps extends PageParams {
  pageName: string
  hideMenu?: boolean
  theme?: Theme
  perectScroll?: boolean
  noFrame?: boolean
}

export const PageFrame: React.FC<PageFrameProps> = ({
  children,
  pageContext: { lang = 'en', hidePostEvent = false },
  isTop = false,
  pageName,
  hideMenu = false,
  theme = siemensTheme,
  onScrollerInit = null,
  perectScroll = true,
  noFrame,
}) => {
  // REDIRECT TRAILING SLASHES
  if (typeof window !== 'undefined') {
    const stripTrailingSlashes = document.location.href.replace(
      /^\/+|\/+$/g,
      ''
    )
    /*  if (document.location.href !== stripTrailingSlashes) {
      navigate(stripTrailingSlashes)

      return <></>
    } */
  }

  const _scrollRef = null

  TimeUtil.setLocale(lang === 'de' ? locale_de : 'en')
  const scrollbarRef = React.useRef()
  const [scrollYSuppressed, setScrollYSuppressed] = React.useState(false)
  const [scrollKey, setScrollKey] = React.useState()

  const DebugMenuWithControls = () => withDebugMenuControls(DebugMenu)
  const [modalState, setModalState] = React.useState({ showDebugMenu: false })
  const [participations, setParticipations] = React.useState<
    string[] | undefined
  >()
  const [hasAppointment, setHasAppointment] = React.useState<boolean>(false)
  const byPassAuth: boolean =
    pageName === 'support' ||
    pageName === 'showroom' ||
    pageName === 'forbidden' ||
    pageName === 'cookies' ||
    pageName === 'debug' ||
    pageName === 'salesform-7hs5Hz3as' ||
    pageName === 'dialog-appointment'

  const {
    state: { isAuthenticated, loading },
  } = useAuthentication()

  const {
    state: { isFullscreen },
  } = useFullscreenContext()

  const {
    state: { status: gobalStatus },
  } = useGlobalContext()

  React.useEffect(() => {
    //              options={{suppressScrollY:gobalStatus?.mobileMenuOpen || isFullscreen}}>
    setScrollYSuppressed(gobalStatus?.mobileMenuOpen || isFullscreen)

    if (scrollKey) {
      scrollKey.scrollTop = 0
    }
  }, [gobalStatus, isFullscreen])

  React.useEffect(() => {
    //              options={{suppressScrollY:gobalStatus?.mobileMenuOpen || isFullscreen}}>
    //setScrollKey(scrollKey + 1)
  }, [scrollYSuppressed])

  const { state: mobileVodState } = useVodContext()

  // CHECK MOCK DATE URL
  const dateString = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  ).get('mockDate')

  if (dateString) {
    const mockDate = TimeUtil.getUtc(dateString)

    const diff = dayjs.utc().diff(mockDate, 'ms')
    LocalStorageUtil.remove(MOCK_TIME)
    LocalStorageUtil.saveData(MOCK_TIME, { mockDiffMs: diff })
  }

  const {
    dispatch: modalDispatch,
    state: { showModal, component: modalComponent, params: modalParams },
  } = useModalContext()

  const { phase2 } = useFeatureFlags()

  const getParticipations = async () => {
    const eventPart = await ProgramService.eventParticipation()
    setParticipations(Object.keys(eventPart).map(k => k))
    const appointmentRaw: ProgramEvent[] = await AppointmentService.getAppointments()
    setHasAppointment(appointmentRaw.length > 0)
    if (!phase2) {
      if (
        pageName === 'appointments' &&
        Object.keys(eventPart).map(k => k).length < 1 &&
        Object.keys(appointmentRaw).map(k => k).length < 1
      ) {
        if (typeof window !== 'undefined') {
          navigate(`/${lang}`)
        }
      }
    }
  }

  const {
    state: { reservations },
    dispatch: dispatchReservation,
  } = useReservationContext()

  useEffect(() => {
    // REDIRECT IS HIDE ON POST EVENT
    if ((hidePostEvent || pageName === 'appointments') && isPostEvent()) {
      if (pageName === 'appointments') {
        if (participations && participations?.length < 1 && !hasAppointment) {
          if (typeof window !== 'undefined') {
            navigate(`/${lang}`)
          }
        }
      } else {
        if (typeof window !== 'undefined') {
          navigate(`/${lang}`)
        }
      }
    }

    function handlekeydownEvent(event: KeyboardEvent) {
      const { code, altKey } = event
      if (code === 'KeyD' && altKey) {
        setModalState({ showDebugMenu: true })
      }
      if (code === 'KeyT' && altKey) {
        setModalState({ showDebugMenu: true })
      }
    }
    document.addEventListener('keyup', handlekeydownEvent)

    return () => {
      document.removeEventListener('keyup', handlekeydownEvent)
    }
  }, [])

  useEffect(() => {
    if (byPassAuth) {
      return
    }
    if (!isAuthenticated && !loading) {
      navigate(`/${lang}/forbidden`)
    }
    if (!loading) {
      AnalyticsTransformer.setUserData()
    }
    if (isAuthenticated && !loading) {
      getParticipations()
    }
  }, [isAuthenticated, loading])

  const currentheme = theme

  if (hidePostEvent && isPostEvent()) {
    return <></>
  }

  const content = (
    <>
      {loading && (
        <Loading>
          <LoadingIndicator />
        </Loading>
      )}
      <Modal
        show={modalState.showDebugMenu}
        className="debugModal"
        onClickOutside={() => setModalState({ showDebugMenu: false })}
        style={{ zIndex: 3000 }}
      >
        <DebugMenuWithControls></DebugMenuWithControls>
      </Modal>

      {showModal && (
        <Modal
          show={true}
          onClose={modalParams?.onClose}
          onClickOutside={() => {
            modalParams?.onClose &&
              modalParams?.onClose(modalParams?.success || undefined)
            modalDispatch({
              type: 'HIDE_MODAL',
            })
          }}
        >
          {modalComponent &&
            React.createElement(modalComponent, { ...modalParams })}
        </Modal>
      )}

      <MobileVodVideo
        src={mobileVodState.src}
        showMobile={mobileVodState.showMobile}
        trackingTitle={mobileVodState.trackingTitle}
        playerName={mobileVodState.playerName}
      />

      {!noFrame && (
        <Header
          hideMenu={!isAuthenticated || hideMenu}
          isTop={isTop}
          hasParticipation={
            (participations && participations?.length > 0) || hasAppointment
          }
        />
      )}
      {!loading && <InnerContainer isTop={isTop}>{children}</InnerContainer>}
      <SlideIn />
      {!noFrame && (
        <>
          <CookieConsent page={pageName} lang={lang} />
          <Footer currentLanguage={lang} />
        </>
      )}
    </>
  )

  return (
    <>
      <Helmet>
        <title>Siemens | Cybersecurity 2021</title>
        <meta name="description" content="" />
      </Helmet>
      <LoadAnalytics />

      <TranslationProvider
        translations={I18nUtil.flattenLocales(I18nUtil.getLocale(lang))}
      >
        <ThemeProvider theme={theme}>
          <CustomThemeProvider theme={theme}>
            <AtomsProvider
              customAtoms={{
                AgendaCta,
                Modal,
                Button,
                LoadingSpinner,
                DraggableSlider,
                FakeFullscreen,
              }}
            >
              {perectScroll ? (
                <PerfectScrollbar
                  containerRef={ref => {
                    setScrollKey(ref)
                    onScrollerInit && onScrollerInit(ref)
                  }}
                  onScrollY={container => {
                    if (scrollYSuppressed) {
                      container.scrollTop = 0
                    }
                  }}
                >
                  {content}
                </PerfectScrollbar>
              ) : (
                <>{content} </>
              )}
            </AtomsProvider>
          </CustomThemeProvider>
        </ThemeProvider>
      </TranslationProvider>
      <Global
        styles={{
          html: {
            height: '100%',
            overflowY: 'none',
            position:
              gobalStatus?.mobileMenuOpen || isFullscreen ? 'fixed' : 'inherit',
            overscrollBehavior: 'none',

            backgroundColor: currentheme.colors.bodyBackground,
            fontFamily: currentheme.fonts.fontFamily,
            color: currentheme.colors.text,
            body: {
              borderTop:
                LocalStorageUtil.getString('mockTime') ||
                LocalStorageUtil.getString('mockTimezone')
                  ? `10px solid ${currentheme.colors.error}`
                  : 'none',
            },
            '.customTooltip': {
              background: currentheme.colors.buttonSecondaryBackground,
              maxWidth: '200px',
              zIndex: 999999,
              '&:after': {
                borderTopColor: `${currentheme.colors.buttonSecondaryBackground} !important`,
              },
            },
            '.showroomTooltip': {
              background: rgba(currentheme.colors.lightBlue, 1),
              maxWidth: '200px',
              borderRadius: 0,
              fontSize: '12px',
              zIndex: 999999,
              '&:after': {
                borderTopColor: `${currentheme.colors.lightBlue} !important`,
              },
            },
            '.debugModal > div': {
              background: currentheme.colors.bodyBackground,
              input: {
                color: '#000',
              },
            },
            '.crisp-client': {
              display: pageName === 'support' ? 'block' : 'none',
            },
          },
        }}
      ></Global>
    </>
  )
}
export default withTrans(PageFrame)

const InnerContainer = styled.div<InnerContainerParams>(
  ({ theme: { grid }, isTop }) => `
  padding-top:50px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
  min-height: calc(100vh - 330px);

  ${isTop && 'padding-top:0px; margin-top:0px; min-height:100vh; '}

  ${mq[3]} {
    padding-top:0px;

    min-height: calc(100vh - 370px);
    ${isTop && 'margin-top:-150px; '}
  }


`
)

const Loading = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:fixed;
  z-index:1003;
  width:100vw;
  height:100vh;
  left:0;top:0;
  backdrop-filter: blur(5px);
  display:flex;
  align-items:center;justify-content:center;
  background:${rgba(colors.bodyBackground, 0.5)};
`
)
const DebugBarTop = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:fixed;
  z-index:1003;
  width:100vw;
  padding:5px;
  top:0;
  display:flex;
  align-items:center;
  justify-content:center;
  background:${rgba(colors.error, 0.5)};
`
)
